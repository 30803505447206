module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-175813021-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Miss Dulces","short_name":"Miss Dulces","start_url":"/","background_color":"#ffe2ff","theme_color":"#f49ac1","icon":"src/images/logo-miss-dulces-maskable.png","icons":[{"src":"/favicons/logo-miss-dulces-maskable.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"lang":"es","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a6cc0661fcdd49e74755bc43a4bbde0b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
